import React from "react";
import { arrayOf, string } from "prop-types";
import Section from "../Base/Section";
import Heading from "../Base/HeadingBuilder";
import Image from "../../components/ImageQuerys/LargeFeaturesImages";
import circle from "../../images/svg/circle.svg";

const ImageWithText = ({ className, heading, paragraphs, imageName }) => {
  return (
    <Section
      className={`
        flex flex-col lg:flex-row justify-between items-start
        gap-10 xl:gap-20 relative
        ${className}
      `}
    >
      <img
        alt="background circle"
        src={circle}
        className="absolute -left-60 top-0 max-w-[500px]"
      />
      <div className="w-full self-center xl:self-start">
        <Image imageName={imageName} />
      </div>

      <div className="w-full relative">
        <Heading
          level={3}
          injectionType={3}
          withBlueSymbol
          className="lg:max-w-[310px]"
        >
          {heading}
        </Heading>

        <div className="flex flex-col gap-y-2 md:max-w-[550px] lg:max-w-[430px]">
          {paragraphs.map((p) => (
            <p
              key={p}
              className="text-p text-black-300"
              dangerouslySetInnerHTML={{ __html: p }}
            />
          ))}
        </div>
      </div>
    </Section>
  );
};

ImageWithText.propTypes = {
  className: string,
  heading: string,
  paragraphs: arrayOf(string).isRequired,
  imageName: string.isRequired,
};

ImageWithText.defaultProps = {
  className: "",
  heading: null,
};

export default ImageWithText;
